// extracted by mini-css-extract-plugin
export var container = "index-module--container--1IaaP";
export var menuButton = "index-module--menuButton--HjPv9";
export var rightButtons = "index-module--rightButtons--86-N2";
export var search = "index-module--search--1U44j";
export var content = "index-module--content--1I4Vk";
export var scrolledClass = "index-module--scrolledClass--1mw-a";
export var leftHidden = "index-module--leftHidden--2IlTc";
export var languageSwitch = "index-module--languageSwitch--3Lxf7";
export var rightHidden = "index-module--rightHidden--z0qZM";
export var mainIcon = "index-module--mainIcon--3_oLU";
export var displayed = "index-module--displayed--NIw2e";